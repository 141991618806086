<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_service") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="columns.name.title" prop="name">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>

        <!-- end col -->
        <el-col :span="12">
          <el-form-item :label="columns.parent_id.title" prop="parent_id">
            <!-- <el-select v-model="form.parent_id" :placeholder="columns.parent_id.title" filterable
                            clearable>
                            <el-option v-for="item in services" :key="item.id + item.name" :label="item.name"
                                :value="item.id"></el-option>
                        </el-select> -->
            <select-service
              :service_id="form.parent_id"
              v-model="form.parent_id"
              @input="getValue"
            ></select-service>
          </el-form-item>
        </el-col>
        <!-- end col -->

        <el-col :span="12">
          <el-form-item :label="columns.code.title" prop="code">
            <el-input v-model="form.code" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <!-- end col -->

        <el-col :span="12">
          <el-form-item :label="columns.price.title">
            <div class="el-input el-input--small">
              <money
                v-model="form.price"
                v-bind="money"
                class="el-input__inner"
              ></money>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item :label="columns.discount_price.title">
            <div class="el-input el-input--small">
              <el-input
                type="number"
                step="any"
                :min="0"
                :max="100"
                v-model="form.discount_price"
              >
              </el-input>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="columns.life_time.title">
            <div class="el-input el-input--small">
              <el-input
                type="number"
                step="any"
                :min="0"
                :max="100"
                size="medium"
                v-model="form.life_time"
              >
              </el-input>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.is_parent"
                :label="$t('message.parent')"
                border
                size="medium"
              ></el-checkbox>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.is_physio"
                :label="$t('message.procedural')"
                border
                size="medium"
              ></el-checkbox>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.is_laboratory"
                :label="$t('message.laboratory')"
                border
                size="medium"
              ></el-checkbox>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.letterhead"
                :label="$t('message.letterhead')"
                border
                size="medium"
              ></el-checkbox>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.is_report"
                :label="$t('message.reports')"
                border
                size="medium"
              ></el-checkbox>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <div class="block">
            <span>{{ $t("message.color") }}</span>
            <el-color-picker
              v-loading="loadingData"
              v-model="color"
            ></el-color-picker>
          </div>
        </el-col>

        <el-col :span="24">
          <div class="grid-content mb20">
            <div
              v-for="process in service_process"
              :key="process.id"
              class="servis-pratses"
            >
              <input type="checkbox" v-model="process.check" />
              <h5>{{ process.name }}</h5>
              <input
                :disabled="!process.check"
                type="number"
                size="small"
                v-model="process.amount"
                class="el-input__inner2"
              />
              <select
                :disabled="!process.check"
                v-model="process.amount_type"
                class="mx-2 mb-1 w-100 el-input__inner2"
                placeholder="Select"
                size="small"
              >
                <option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </select>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- end row -->
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
import selectService from "@/components/inventory-select/select-service";

export default {
  mixins: [form, drawerChild],
  props: ["selected", "reloadModel"],
  components: {
    selectService,
  },
  data() {
    return {
      color: "",
      selected_parent_id: [],
      service_process: [],
      options: [
        { value: "cash", label: "Сумма" },
        { value: "percent", label: "Процент" },
      ],
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.fetchData();
  },
  async mounted() {
    if (this.categories && this.categories.length === 0)
      await this.loadCategories();

    if (this.processes && this.processes.length === 0)
      await this.loadProcesses();
    this.updateForm();
  },
  computed: {
    ...mapGetters({
      services: "services/list",
      rules: "services/rules",
      model: "services/model",
      processes: "processes/list",
      columns: "services/columns",
      categories: "categoryServices/list",
      money: "money",
    }),
  },
  methods: {
    ...mapActions({
      save: "services/update",
      editModel: "services/show",
      loadCategories: "categoryServices/index",
      loadProcesses: "processes/index",
      colorServices: "services/color",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    getValue() {
      if (this.selected_parent_id.length) {
        let color = this.selected_parent_id.filter(
          (val) => val.id == this.form.parent_id
        );

        this.color = color.length ? color[0].color : "";
      } else {
        this.color = "";
      }
    },
    async afterOpened() {
      await this.loadProcesses();
      if (this.selected) {
        this.loadingData = true;
        await this.editModel(this.selected.id)
          .then(async (res) => {
            this.loadingData = false;
            this.form = JSON.parse(JSON.stringify(this.model));
            this.updateForm();
          })
          .catch((err) => {
            this.loadingData = false;
            this.$alert(err);
          });
      }
    },
    afterClosed() {
      this.service_process = [];
      this.selected_parent_id = [];
      this.resetForm("form");
      this.empty();
      this.form = JSON.parse(JSON.stringify());
    },
    async updateForm() {
      this.service_process = this.processes;
      await this.form.process_service.forEach((val) => {
        this.service_process.find((x) => x.id === val.process_id).check = true;
        this.service_process.find((x) => x.id === val.process_id).amount =
          val.amount;
        this.service_process.find((x) => x.id === val.process_id).amount_type =
          val.amount_type;
      });
    },
    fetchData() {
      this.loadingData = true;
      const query = { selected_relation: true };
      this.colorServices(query)
        .then((res) => {
          this.loadingData = false;
          this.selected_parent_id = res.data.services.data;
        })
        .catch((err) => {});
    },

    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.processes = this.service_process.filter(
            (val) => val.check == true
          );
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();

              this.resetForm("form");
              this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.reloadForm();
      this.$emit("c-close", { drawer: "drawerUpdate" });
      this.close();
    },
  },
};
</script>
<style >
.khan_se .el-select-dropdown__item {
  width: 600px;
  overflow: initial;
  height: inherit;
  white-space: initial;
}

.ser_chek {
  margin-top: 18px;
}

.ser_chek label.el-checkbox {
  width: 100%;
}
.servis-pratses {
  background: #e1e8f1;
  padding: 10px;
  padding-bottom: 3px;
  border-radius: 4px;
  display: flex;
  margin-bottom: 10px;
}
.servis-pratses h5 {
  min-width: 250px;
  margin-left: 5px;
  font-size: 14px;
}
.el-input__inner2 {
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.el-input__inner2:disabled {
  background: #f6f8fb;
}
</style>